const ROUTES = [
  '/abrollkipper/',
  '/absetzkipper/',
  '/containerchassis/',
  '/kofferauflieger/',
  '/kuehlauflieger/',
  '/planenauflieger/',
  '/sattelauflieger-angebot/',
  '/schubbodenauflieger/',
  '/zugmaschinen-angebot/',
  '/zugmaschinen/',
]

const URLS = [
  {
    origin: '/supplier/',
    target: '/vermieten/',
  },
  {
    origin: '/about/',
    target: '/ueber-uns/',
  },
  {
    origin: '/contact/',
    target: '/kontakt/',
  },
  {
    origin: '/imprint/',
    target: '/impressum/',
  },
  {
    origin: '/privacy/',
    target: '/datenschutz/',
  },
  {
    origin: '/terms/',
    target: '/AGB/mieter/',
  },
  {
    origin: '/find/',
    target: '/nutzfahrzeugsuche/',
  },
  {
    origin: '/lkw/',
    target: '/request-quote',
    query: { category: 'lkw' },
  },
  {
    origin: '/auflieger/',
    target: '/request-quote',
    query: { category: 'trailer' },
  },
  {
    origin: '/sattelzugmaschinen/',
    target: '/request-quote',
    query: { category: 'truck' },
  },
]

export default defineNuxtRouteMiddleware((to, from) => {
  const path = to.path.slice(-1) === '/' ? to.path : `${to.path}/`

  const urlParams = Object.keys(from.query).reduce((acc, key) => {
    if (
      key.includes('utm') ||
      key.includes('location') ||
      key.includes('partner')
    ) {
      acc[key] = from.query[key]
    }
    return acc
  }, {})

  if (ROUTES.includes(path)) {
    return navigateTo({
      name: 'request-assets',
      query: { ...to.query, ...urlParams },
    })
  }

  const redirectObject = URLS.find((item) => item.origin === path)

  if (redirectObject) {
    const query = redirectObject.query
      ? { ...to.query, ...redirectObject.query, ...urlParams }
      : { ...to.query, ...urlParams }

    return navigateTo(
      { path: redirectObject.target, query: query },
      { redirectCode: 308 }
    )
  }

  if (from.fullPath === to.fullPath) return

  // Conditions: there is utm parameters, process is in the client and it's been redirected from another page (otherwhise it's undefined)
  if (Object.keys(urlParams).length && process.client && !to.redirectedFrom) {
    return navigateTo({ path: to.path, query: { ...to.query, ...urlParams } })
  }
})
