import { default as mieterhymzzoSpGGMeta } from "/vercel/path0/pages/AGB/mieter.vue?macro=true";
import { default as vermieterBDgPiDZBSoMeta } from "/vercel/path0/pages/AGB/vermieter.vue?macro=true";
import { default as _91slug_93ONTRCFwGiTMeta } from "/vercel/path0/pages/auflieger/[slug].vue?macro=true";
import { default as invitation419vjd0S8DMeta } from "/vercel/path0/pages/auth/invitation.vue?macro=true";
import { default as recoverylm8wIpdohQMeta } from "/vercel/path0/pages/auth/password/recovery.vue?macro=true";
import { default as resetYcjzWS9Un8Meta } from "/vercel/path0/pages/auth/password/reset.vue?macro=true";
import { default as welcomeqjyldHOkHLMeta } from "/vercel/path0/pages/auth/welcome.vue?macro=true";
import { default as _91slug_93Po03rnzIFtMeta } from "/vercel/path0/pages/blog/[slug].vue?macro=true";
import { default as indexmfxAx2eWMBMeta } from "/vercel/path0/pages/blog/index.vue?macro=true";
import { default as _91id_93WfVkVMnnRsMeta } from "/vercel/path0/pages/confirm-offer/[id].vue?macro=true";
import { default as datenschutzKnOBKfKJS8Meta } from "/vercel/path0/pages/datenschutz.vue?macro=true";
import { default as eventsiPr0A4oVc9Meta } from "/vercel/path0/pages/events.vue?macro=true";
import { default as impressumTRtm3XZBRUMeta } from "/vercel/path0/pages/impressum.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as kontaktgUfu4Sf1oqMeta } from "/vercel/path0/pages/kontakt.vue?macro=true";
import { default as _91slug_93M4JnKScG8GMeta } from "/vercel/path0/pages/lkw/[slug].vue?macro=true";
import { default as logint0AWlhQgM0Meta } from "/vercel/path0/pages/login.vue?macro=true";
import { default as become_45supplierCh9CUGI5HRMeta } from "/vercel/path0/pages/plattform/become-supplier.vue?macro=true";
import { default as buchungenNbjASl9dtRMeta } from "/vercel/path0/pages/plattform/buchungen.vue?macro=true";
import { default as handover_45requests3EITHZ9e9uMeta } from "/vercel/path0/pages/plattform/handover-requests.vue?macro=true";
import { default as index_45oldjowc9uBy9qMeta } from "/vercel/path0/pages/plattform/index-old.vue?macro=true";
import { default as indexVyjOSGmF3DMeta } from "/vercel/path0/pages/plattform/index.vue?macro=true";
import { default as indexXvsszONDdIMeta } from "/vercel/path0/pages/plattform/locations/index.vue?macro=true";
import { default as indexwfK9VxuJPkMeta } from "/vercel/path0/pages/plattform/marketplace/[id]/index.vue?macro=true";
import { default as oldRiVZzwBasxMeta } from "/vercel/path0/pages/plattform/marketplace/[id]/old.vue?macro=true";
import { default as indexGU4WFdKyhrMeta } from "/vercel/path0/pages/plattform/marketplace/index.vue?macro=true";
import { default as ordersnJTUaDw7gpMeta } from "/vercel/path0/pages/plattform/orders.vue?macro=true";
import { default as index819NnUKx0GMeta } from "/vercel/path0/pages/plattform/registrieren/index.vue?macro=true";
import { default as _91id_93xuBBfPY0XRMeta } from "/vercel/path0/pages/plattform/rentals/[id].vue?macro=true";
import { default as index4W4znDFt6wMeta } from "/vercel/path0/pages/plattform/requests/index.vue?macro=true";
import { default as detailswrFSTS8dC8Meta } from "/vercel/path0/pages/plattform/vehicles/[id]/details.vue?macro=true";
import { default as editgyfl99aW3ZMeta } from "/vercel/path0/pages/plattform/vehicles/[id]/edit.vue?macro=true";
import { default as indexcFE18SmG2wMeta } from "/vercel/path0/pages/plattform/vehicles/index.vue?macro=true";
import { default as newoSe9PDh3aqMeta } from "/vercel/path0/pages/plattform/vehicles/new.vue?macro=true";
import { default as plattformQTPMHPN2mwMeta } from "/vercel/path0/pages/plattform.vue?macro=true";
import { default as quizeuiOualNC8Meta } from "/vercel/path0/pages/quiz.vue?macro=true";
import { default as indexAwL9z7MlvzMeta } from "/vercel/path0/pages/request-quote/index.vue?macro=true";
import { default as assetsMnKMFvSI5oMeta } from "/vercel/path0/pages/request/assets.vue?macro=true";
import { default as companyE7X6nTXjHJMeta } from "/vercel/path0/pages/request/company.vue?macro=true";
import { default as requirementsPY5HqAJxEvMeta } from "/vercel/path0/pages/request/requirements.vue?macro=true";
import { default as successICzSIcFLyRMeta } from "/vercel/path0/pages/request/success.vue?macro=true";
import { default as timingxw2mrl5kv9Meta } from "/vercel/path0/pages/request/timing.vue?macro=true";
import { default as requestOtEOFbMklHMeta } from "/vercel/path0/pages/request.vue?macro=true";
import { default as _91slug_93ebcEipPCqyMeta } from "/vercel/path0/pages/sattelzugmaschinen/[slug].vue?macro=true";
import { default as signupY93ILdpGpbMeta } from "/vercel/path0/pages/signup.vue?macro=true";
import { default as ueber_45unsToge4ZIkjdMeta } from "/vercel/path0/pages/ueber-uns.vue?macro=true";
import { default as _91itemid_939HqmoBNHv2Meta } from "/vercel/path0/pages/vehicle-files/[itemid].vue?macro=true";
import { default as _91itemid_93uyzVxTlE61Meta } from "/vercel/path0/pages/vehicle-requests/[itemid].vue?macro=true";
import { default as vermietentAEmrQOti3Meta } from "/vercel/path0/pages/vermieten.vue?macro=true";
import { default as component_45stub5X4Ei38PMgMeta } from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stub5X4Ei38PMg } from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "AGB-mieter",
    path: "/AGB/mieter",
    component: () => import("/vercel/path0/pages/AGB/mieter.vue").then(m => m.default || m)
  },
  {
    name: "AGB-vermieter",
    path: "/AGB/vermieter",
    component: () => import("/vercel/path0/pages/AGB/vermieter.vue").then(m => m.default || m)
  },
  {
    name: "auflieger-slug",
    path: "/auflieger/:slug()",
    component: () => import("/vercel/path0/pages/auflieger/[slug].vue").then(m => m.default || m)
  },
  {
    name: "auth-invitation",
    path: "/auth/invitation",
    component: () => import("/vercel/path0/pages/auth/invitation.vue").then(m => m.default || m)
  },
  {
    name: "auth-password-recovery",
    path: "/auth/password/recovery",
    component: () => import("/vercel/path0/pages/auth/password/recovery.vue").then(m => m.default || m)
  },
  {
    name: "auth-password-reset",
    path: "/auth/password/reset",
    component: () => import("/vercel/path0/pages/auth/password/reset.vue").then(m => m.default || m)
  },
  {
    name: "auth-welcome",
    path: "/auth/welcome",
    component: () => import("/vercel/path0/pages/auth/welcome.vue").then(m => m.default || m)
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    component: () => import("/vercel/path0/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog",
    path: "/blog",
    component: () => import("/vercel/path0/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "confirm-offer-id",
    path: "/confirm-offer/:id()",
    component: () => import("/vercel/path0/pages/confirm-offer/[id].vue").then(m => m.default || m)
  },
  {
    name: "datenschutz",
    path: "/datenschutz",
    component: () => import("/vercel/path0/pages/datenschutz.vue").then(m => m.default || m)
  },
  {
    name: "events",
    path: "/events",
    component: () => import("/vercel/path0/pages/events.vue").then(m => m.default || m)
  },
  {
    name: "impressum",
    path: "/impressum",
    component: () => import("/vercel/path0/pages/impressum.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "kontakt",
    path: "/kontakt",
    component: () => import("/vercel/path0/pages/kontakt.vue").then(m => m.default || m)
  },
  {
    name: "lkw-slug",
    path: "/lkw/:slug()",
    component: () => import("/vercel/path0/pages/lkw/[slug].vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/vercel/path0/pages/login.vue").then(m => m.default || m)
  },
  {
    name: plattformQTPMHPN2mwMeta?.name,
    path: "/plattform",
    meta: plattformQTPMHPN2mwMeta || {},
    component: () => import("/vercel/path0/pages/plattform.vue").then(m => m.default || m),
    children: [
  {
    name: "plattform-become-supplier",
    path: "become-supplier",
    component: () => import("/vercel/path0/pages/plattform/become-supplier.vue").then(m => m.default || m)
  },
  {
    name: "plattform-buchungen",
    path: "buchungen",
    component: () => import("/vercel/path0/pages/plattform/buchungen.vue").then(m => m.default || m)
  },
  {
    name: "plattform-handover-requests",
    path: "handover-requests",
    component: () => import("/vercel/path0/pages/plattform/handover-requests.vue").then(m => m.default || m)
  },
  {
    name: "plattform-index-old",
    path: "index-old",
    component: () => import("/vercel/path0/pages/plattform/index-old.vue").then(m => m.default || m)
  },
  {
    name: "plattform",
    path: "",
    component: () => import("/vercel/path0/pages/plattform/index.vue").then(m => m.default || m)
  },
  {
    name: "plattform-locations",
    path: "locations",
    component: () => import("/vercel/path0/pages/plattform/locations/index.vue").then(m => m.default || m)
  },
  {
    name: "plattform-marketplace-id",
    path: "marketplace/:id()",
    component: () => import("/vercel/path0/pages/plattform/marketplace/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "plattform-marketplace-id-old",
    path: "marketplace/:id()/old",
    component: () => import("/vercel/path0/pages/plattform/marketplace/[id]/old.vue").then(m => m.default || m)
  },
  {
    name: "plattform-marketplace",
    path: "marketplace",
    component: () => import("/vercel/path0/pages/plattform/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: "plattform-orders",
    path: "orders",
    component: () => import("/vercel/path0/pages/plattform/orders.vue").then(m => m.default || m)
  },
  {
    name: "plattform-registrieren",
    path: "registrieren",
    component: () => import("/vercel/path0/pages/plattform/registrieren/index.vue").then(m => m.default || m)
  },
  {
    name: "plattform-rentals-id",
    path: "rentals/:id()",
    component: () => import("/vercel/path0/pages/plattform/rentals/[id].vue").then(m => m.default || m)
  },
  {
    name: "plattform-requests",
    path: "requests",
    component: () => import("/vercel/path0/pages/plattform/requests/index.vue").then(m => m.default || m)
  },
  {
    name: "plattform-vehicles-id-details",
    path: "vehicles/:id()/details",
    component: () => import("/vercel/path0/pages/plattform/vehicles/[id]/details.vue").then(m => m.default || m)
  },
  {
    name: "plattform-vehicles-id-edit",
    path: "vehicles/:id()/edit",
    component: () => import("/vercel/path0/pages/plattform/vehicles/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "plattform-vehicles",
    path: "vehicles",
    component: () => import("/vercel/path0/pages/plattform/vehicles/index.vue").then(m => m.default || m)
  },
  {
    name: "plattform-vehicles-new",
    path: "vehicles/new",
    component: () => import("/vercel/path0/pages/plattform/vehicles/new.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "quiz",
    path: "/quiz",
    component: () => import("/vercel/path0/pages/quiz.vue").then(m => m.default || m)
  },
  {
    name: "request-quote",
    path: "/request-quote",
    component: () => import("/vercel/path0/pages/request-quote/index.vue").then(m => m.default || m)
  },
  {
    name: "request",
    path: "/request",
    meta: requestOtEOFbMklHMeta || {},
    component: () => import("/vercel/path0/pages/request.vue").then(m => m.default || m),
    children: [
  {
    name: "request-assets",
    path: "assets",
    component: () => import("/vercel/path0/pages/request/assets.vue").then(m => m.default || m)
  },
  {
    name: "request-company",
    path: "company",
    component: () => import("/vercel/path0/pages/request/company.vue").then(m => m.default || m)
  },
  {
    name: "request-requirements",
    path: "requirements",
    component: () => import("/vercel/path0/pages/request/requirements.vue").then(m => m.default || m)
  },
  {
    name: "request-success",
    path: "success",
    component: () => import("/vercel/path0/pages/request/success.vue").then(m => m.default || m)
  },
  {
    name: "request-timing",
    path: "timing",
    component: () => import("/vercel/path0/pages/request/timing.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "sattelzugmaschinen-slug",
    path: "/sattelzugmaschinen/:slug()",
    component: () => import("/vercel/path0/pages/sattelzugmaschinen/[slug].vue").then(m => m.default || m)
  },
  {
    name: "signup",
    path: "/signup",
    component: () => import("/vercel/path0/pages/signup.vue").then(m => m.default || m)
  },
  {
    name: "ueber-uns",
    path: "/ueber-uns",
    component: () => import("/vercel/path0/pages/ueber-uns.vue").then(m => m.default || m)
  },
  {
    name: "vehicle-files-itemid",
    path: "/vehicle-files/:itemid()",
    component: () => import("/vercel/path0/pages/vehicle-files/[itemid].vue").then(m => m.default || m)
  },
  {
    name: "vehicle-requests-itemid",
    path: "/vehicle-requests/:itemid()",
    component: () => import("/vercel/path0/pages/vehicle-requests/[itemid].vue").then(m => m.default || m)
  },
  {
    name: "vermieten",
    path: "/vermieten",
    component: () => import("/vercel/path0/pages/vermieten.vue").then(m => m.default || m)
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/elektro/:pathMatch(.*)",
    component: component_45stub5X4Ei38PMg
  }
]